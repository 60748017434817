import { ArrowRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import Link from 'next/link'
import { useCallback, useContext, useMemo, useRef } from 'react'
import Slider from 'react-slick'
import { LandingLayout } from 'src/components/Layout/LandingLayout'
import News from 'src/components/News'
import { SEO } from 'src/components/SEO'
import ShowOff from 'src/components/ShowOff'
import TrackingForm from 'src/components/TrackingForm'
import CardView from 'src/components/CardView'
import ContactForm from 'src/components/ContactForm'

import BASE_CONFIG from 'src/configs/base'
import MENU_CONFIGS from 'src/configs/menu'
import { IMAGE_HOST } from 'src/configs/API'
import commonServices from 'src/services/common.service'
import { default as newsService, default as newsServices } from 'src/services/news.service'
import { convertImagePath } from 'src/utils/common'
import { GlobalContext } from './_app'
import Image from 'next/image'
import { useRouter } from 'next/router'

function Home({ news, cards, statistics }) {
	const router = useRouter();
	const { menuItems } = useContext(GlobalContext)
	const { pinPost } = newsServices.usePinPost()
	const sliderRef = useRef()

	const handlePrevSliderBtnClick = useCallback(() => {
		sliderRef.current?.slickPrev()
	}, [sliderRef])

	const handleNextSliderBtnClick = useCallback(() => {
		sliderRef.current?.slickNext()
	}, [sliderRef])

	const aboutUsItems = useMemo(() => {
		return menuItems.find((e) => e.Url == MENU_CONFIGS.ABOUT_US_SLUG) || {}
	}, [menuItems])

	const statisticsArr = useMemo(() => {
		return statistics.map((e) => ({
			id: e.Value,
			url: e.Link,
			value: e.Key,
			key: e.Value,
		}))
	}, [statistics])

	const AboutUs = () => (
		<div className="about__us-view">
			{aboutUsItems.Children?.map((e) => (
				<div key={`home_about_${e.Url}`}>
					<Link href={e.Url}>
					<a aria-label={"247Express-Nhanh hơn bạn nghĩ"} className="about__us-link">
							{e.Name}
							<RightOutlined style={{ transform: 'translateY(5px)' }} />
							</a>
					</Link>
				</div>
			))}
		</div>
	)

	return (
		<div id="home">
			<SEO
				data={{
					title: `Trang chủ | ${BASE_CONFIG.SLOGAN}`,
					description: '247Express | Nhanh hơn bạn nghĩ',
					keywords: '247Express, 247, express, nhanh hơn bạn nghĩ',
					url: BASE_CONFIG.PAGE_URL+router.asPath
				}}
			/>
			<div className="home-banner">
				<Image
					src={'/images/resize_home_banner.jpg'}
					alt={'Chào mừng bạn'}
					layout="fill"
					objectFit="cover"
					priority
					blurDataURL={'/images/resize_home_banner.jpg'}
					placeholder="blur"
				/>
				<div className="container banner-container">
					<div className="banner-greeting">
						<span>Chào mừng bạn đến</span>
						<h1>{BASE_CONFIG.PAGE_NAME}</h1>
					</div>
					<div className="banner-form desktop__only">
						<TrackingForm />
					</div>
				</div>
			</div>
			<div className="mobile-form mobile__only">
				<TrackingForm />
			</div>
			<div className="home-show__off">
				<ShowOff data={statisticsArr} />
			</div>
			{/* Ngày vàng ưu đãi */}
			<div className="home-sale">
				<div className="container">
					<div className="sale-slider">
						<div className="slider-btns">
							<button onClick={handlePrevSliderBtnClick} className="btns-prev">
								<LeftOutlined style={{ fontSize: 20 }} />
							</button>
							<button onClick={handleNextSliderBtnClick} className="btns-next">
								<RightOutlined style={{ fontSize: 20 }} />
							</button>
						</div>
						<Slider ref={sliderRef} slidesToShow={1} arrows={false}>
							{cards.map((c) => (
								<div key={c._Id} className="slider-wrapper">
									<CardView
										title={c.Title}
										redTexts={4}
										imgUrl={`${IMAGE_HOST}${c.Image}`}
										link={c.LinkDetail}
										content={
											<div className="home__card">
												<p>{c.Content}</p>
												{c.LinkDetail && (
													<div className="home__card-btn">
														<Button type="primary" onClick={()=> window.open(c.LinkDetail)}>
															<Link href={c.LinkDetail} >
																<>
																	Xem chi tiết
																	<ArrowRightOutlined style={{ marginLeft: 8 }} />
																	</>
															</Link>
														</Button>
													</div>
												)}
											</div>
										}
									/>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</div>

			{/* news section */}
			<div className="home-news">
				<div className="container">
					<h1 style={{fontSize:60}}>
						Tin tức sự kiện <span style={{ color: '#9F3123' }}>quan trọng</span>
					</h1>
					<Row>
						<Col lg={16} xl={16} md={24} sm={24} xs={24}>
							<div className="news-left">
								<ul className="left-news__list">
									{news.slice(0, 3).map((e) => (
										<li key={e.Id} className="news__list-item">
											<News data={e} />
										</li>
									))}
								</ul>
							</div>
							<Row>
								<Col span={24} style={{textAlign:"center", paddingTop:30 }}>
									<Button type='link' onClick={()=> {window.location.href = "https://247express.vn/tin-tuc"}}>
										<p className='news-read-more'>Xem thêm</p>
									</Button>
								</Col>
							</Row>
						</Col>
						{pinPost && (
							<Col lg={8} xl={8} md={24} sm={24} xs={24}>
								<div className="news-right">
									<div className="right-hot__news">
										<div className="news-container">
											{/* eslint-disable-next-line @next/next/no-img-element */}
											<Image
												src={convertImagePath(pinPost.Thumb)}
												alt={pinPost.Name}
												layout="fill"
												objectFit="cover"
												// placeholder="blur"
											/>
											<div className="hot__news-info">
												<Link
													href={`/tin-tuc/${pinPost.CategorySlug}/${
														pinPost.SEO?.Slug || BASE_CONFIG.DEFAULT_SEO_SLUG
													}/${pinPost.Id}`}
												>
													
														<h2 className="info-title">{pinPost.Name}</h2>
													
												</Link>
												<p className="info-description">{pinPost.Summary}</p>
											</div>
										</div>
									</div>
								</div>
							</Col>
						)}
					</Row>
				</div>
			</div>

			{/* Về chúng tôi */}
			<div className="home-about__us">
				<div className="container">
					<CardView
						title="Về chúng tôi"
						imgUrl="/images/home_aboutus.png"
						content={<AboutUs />}
						redTexts={3}
						rtl
						link={"#"}
					/>
				</div>
			</div>

			{/* Liên hệ */}
			<div className="home-contact">
				<div className="container">
					<Row>
						<Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
							<div className="contact-form">
								<div className="form__container">
									<ContactForm />
								</div>
							</div>
						</Col>
						<Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}>
							<div className="contact-map">
								<div className="map-container">
									{/* <Map
										postOffices={[
											{
												postOfficeName: 'Công ty 247Express',
												lat: 10.7617871,
												lng: 106.6935881,
												postOfficeID: 247,
												phone: '1900 6980',
												address:
													'Văn phòng HCM: Tầng 8/8.2, Tòa nhà Samco Building, Số 326 Võ Văn Kiệt, Phường Cô Giang, Quận 1, TP.Hồ Chí Minh',
											},
										]}
										setHoverId={() => {}}
										hoverId={247}
										center={[10.7617871, 106.6935881]}
										setCenter={() => {}}
									/> */}
									<div className="gmap_canvas">
										<iframe
											width={'100%'}
											height={496}
											id="gmap_canvas"
											src={
												'https://maps.google.com/maps?q=247express%20T%C3%B2a%20nh%C3%A0%20Samco%20Building,%20S%E1%BB%91%20326%20V%C3%B5%20V%C4%83n%20Ki%E1%BB%87t,%20Ph%C6%B0%E1%BB%9Dng%20C%C3%B4%20Giang,%20Qu%E1%BA%ADn%201,%20TP.H%E1%BB%93%20Ch%C3%AD%20Minh&t=&z=13&ie=UTF8&iwloc=&output=embed'
											}
											frameBorder={0}
											scrolling="no"
											marginHeight={0}
											marginWidth={0}
											title='gmap'
										/>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}

export const getStaticProps = async () => {
	const news = await newsService.getHomeNews()
	const cards = await commonServices.getCards()
	const statistics = await commonServices.getStatistics()

	return { props: { news, cards: cards.Data, statistics: statistics.Data }, revalidate: true }
}

Home.Layout = LandingLayout
export default Home
